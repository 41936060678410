<template>
  <div>
    <h4>SMS Managment</h4>
      
    <data-table
      :data-source="smsOrdred"
      :headers="headers"
      table-class="table-striped table-hover"
    />
  </div>
</template>
  
  <script>
  import {mapGetters} from 'vuex'
  import moment from 'moment'
  import DataTable from '../../../components/dataTable/local.vue'
  import { COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType'
  const Amount = () => import('../../../components/admin/user/smsMontant.vue')
  export default {
      components: { DataTable },
      data(){
          return {
              
          }
      },
      watch: {
          
      },
      methods: {
          
          
      },
      computed: {
          ...mapGetters({
              sms: 'auth/sms',
          }),
          smsOrdred(){
            return [...this.sms].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Numero', name: 'number', type: TEXT_TYPE},
                  {label: 'Message ID', name: 'messageId', type: TEXT_TYPE},
                  {label: 'Message', name: 'message', type: TEXT_TYPE},
                  {label: 'Route', name: 'route', type: TEXT_TYPE},
                  {label: 'Prix', name: 'amount', type: COMPONENT_TYPE, component: Amount},
                  {label: 'Status', name: 'status', type: TEXT_TYPE},
                  {label: 'Date', name: 'createdAt', type: DATE_TYPE}
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>